
.fr-toolbar.fr-top {
  border-radius: 0 !important;
}

.fr-toolbar {
  border: none !important;

  .fr-btn-grp {
    margin: 0 6px;
  }

  .fr-command.fr-btn {
    svg {
      // margin: 5px 5px;
    }
  }
}

.text-editor-action-wrapper {
  .editor-wrapper {
    box-shadow: 0px 0px 2px darkgrey;
    border-radius: 3px;
  }

  .fr-toolbar.fr-sticky-on {
    z-index: 999;
  }

  .fr-second-toolbar {
    display: none;
  }
}
.underlineClass {
  text-decoration: underline!important;
}
